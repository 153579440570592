import React, {useEffect} from 'react';
import {useParams} from "react-router-dom";
import quizData from "../quiz-data";
import ProgressBar from "react-bootstrap/ProgressBar";
import 'bootstrap/dist/css/bootstrap.min.css';
import gsap from "gsap";


const Quiz = () => {
    let params = useParams();
    let quiz = quizData[params.quizName];

    useEffect(() => {
        gsap.fromTo("#root", { opacity: 0}, { opacity: 1, x: 0 });
        quiz.preloadPics.forEach(picture => {
            new Image().src = picture;
        });
    }, [quiz])

    return (
        <div className={"page-bg flex flex-column"} style={{backgroundImage: "url(" + require('../images/topic/'+quiz.name+'/bg.jpg') + ")"}}>
            <div style={{flex: 0.85}}></div>
            <div style={{flex: 0.4, width: "55%", margin: "0 auto"}}>
                <ProgressBar variant="style" now={quiz.progress}/>
            </div>
            <div style={{flex: 2}} className={"flex flex-column"}>
                <div style={{flex: 0.7}}></div>
                <div style={{flex: 2}} className={"flex flex-column align-items-center justify-content-center content"}>
                    <h3>{quiz.question}</h3>
                    <a href={quiz.A}><img className={"button"} alt={"測驗題目 2"} src={require('../images/topic/'+quiz.name+'/btn_1.png')}/> </a>
                    <a href={quiz.B}><img className={"button"} alt={"測驗題目 3"} src={require('../images/topic/'+quiz.name+'/btn_2.png')}/> </a>
                </div>
                <div style={{flex: 2}}></div>
            </div>
            <div style={{flex: 1}}></div>
        </div>
    )
}
export default Quiz;
