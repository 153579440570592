import React, {useEffect} from 'react';
import button1 from "../images/result/btn_1.png";
import button2 from "../images/result/btn_2.png";
import button3 from "../images/result/btn_3.png";
import {useParams} from "react-router-dom";

import gsap from "gsap";
import {Helmet, HelmetProvider} from "react-helmet-async";

const Result = () => {

    useEffect(() => {
        gsap.fromTo("#root", { opacity: 0}, { opacity: 1, x: 0 });
        new Image().src = require("../images/coupon/bg.jpg");
    }, [])

    let params = useParams();
    return (
        <div id={"page-result"} className={"page-bg flex flex-column"} style={{backgroundImage: "url(" + require("../images/result/" + params.resultName + ".jpg") + ")"}}>
            <HelmetProvider>
                <Helmet>
                    <meta property="og:url" content="https://quiz.235n.com.tw/"/>
                    <meta property="og:image" content={"https://quiz.235n.com.tw" + require("../images/social/臉書分享-" + params.resultName + ".jpg")}/>
                </Helmet>
            </HelmetProvider>
            <div style={{flex: 1, padding: "0 10%"}} className={"flex flex-column justify-content-center"}>
                <div style={{flex: 0.5}} className={"color-yellow flex flex-row align-items-end"}>
                    <div >{params.visitorName}在<br/>
                        戀愛中的植物人格是</div>
                </div>
                <div style={{flex: 1}}></div>

            </div>

            <div style={{flex: 1}} className={"flex flex-column align-items-center justify-content-center"}>
                <div style={{flex: 1.5}}></div>
                <div style={{flex: 1}}>
                    <a rel="noreferrer" target={"_blank"} href={"https://www.facebook.com/sharer/sharer.php?u=" + document.location.href}><img className={"result-button"} alt={"鳳梨"} src={button1}/> </a>
                    <a href={"/領取優惠券"}><img className={"result-button"} alt={"領取優惠券"} src={button2}/> </a>
                    <a href={"/"}><img className={"result-button"} alt={"鳳梨"} src={button3}/> </a>
                </div>
                <div style={{flex: 0.5}}></div>
            </div>
        </div>
    )
}
export default Result;
