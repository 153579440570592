import React, {useEffect, useState} from 'react';
import button1 from "../images/name/btn_1.png";
import bg from "../images/name/bg.jpg";
import { useNavigate, useParams} from "react-router-dom";
import gsap from "gsap";


const Name = () => {
    let params = useParams();
    const [visitorName, setVisitorName] = useState("");
    let navigate = useNavigate();
    useEffect(() => {
        gsap.fromTo("#root", { opacity: 0}, { opacity: 1, x: 0 });
        new Image().src = require("../images/result/" + params.resultName + ".jpg");
    }, [params.resultName]);

    const processName = () => {
        if(!visitorName) {
            alert("請輸入您的名字");
        }
        else {
            let url = encodeURI("/測驗結果/"+ params.resultName +"/" + visitorName);
            return navigate(url)
        }
    }

    return (
        <div className={"page-bg flex flex-column"} style={{backgroundImage: "url("+bg+")"}}>
            <div style={{flex: 1}}></div>
            <div style={{flex: 2}} className={"flex flex-column"}>
                <div style={{flex: 2.6}}></div>
                <div style={{flex: 2}} className={"flex flex-column align-items-center justify-content-center content"}>
                    <input type={"text"} name={"name"} value={visitorName} onChange={e => setVisitorName(e.target.value)}/>
                    <br/>
                    <img onClick={processName} className={"button"} alt={"鳳梨"} src={button1}/>
                </div>
                <div style={{flex: 1}}></div>
            </div>
            <div style={{flex: 1}}></div>
        </div>
    )
}
export default Name;
