import React, {useEffect} from 'react';
import button from "../images/index/btn_1.png";
import bg from "../images/index/bg.jpg";
import gsap from "gsap";
const Home = () => {

    useEffect(() => {
        gsap.fromTo("#root", { opacity: 0}, { opacity: 1, x: 0 });
        new Image().src = require("../images/topic/q1/bg.jpg");
    }, [])
    return (
        <div className={"page-bg flex flex-column"} style={{backgroundImage: "url("+bg+")"}}>
            <div style={{flex: 2}}></div>
            <div style={{flex: 1}} className={"flex flex-column"}>
                <div style={{flex: 1}}></div>
                <div style={{flex: 1}} className={"flex align-items-center justify-content-center"}>
                    <a href={"/測驗題目/q1"}><img className={"button"} alt={"Start Quiz"} src={button}/> </a>
                </div>
            </div>
            <div style={{flex: 1}}></div>
        </div>
    )
}
export default Home;
