import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import Home from "./pages/home";
import Name from "./pages/name";
import Result from "./pages/result";
import Quiz from "./pages/quiz";
import Coupon from "./pages/coupon";
import {gsapLoader} from "./gsapLoader";


const router = createBrowserRouter([
    {
        path: "/",
        element: <Home/>,
        loader: gsapLoader
    },
    {
        path: "/測驗題目/:quizName",
        element: <Quiz/>,
        loader: gsapLoader
    },
    {
        path: "/輸入姓名/:resultName",
        element: <Name/>,
        loader: gsapLoader
    },
    {
        path: "/測驗結果/:resultName/:visitorName",
        element: <Result/>,
        loader: gsapLoader
    },{
        path: "/領取優惠券",
        element: <Coupon/>,
        loader: gsapLoader
    }

])

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <RouterProvider router={router}/>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
