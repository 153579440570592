const quizData = {
    q1: {
        name: "q1",
        question: "跟曖昧對象第一次約會\n誰會主動約？",
        A: "/測驗題目/q2",
        B: "/測驗題目/q3",
        progress: 10,
        preloadPics: [
            require("./images/topic/q2/bg.jpg"),
            require("./images/topic/q3/bg.jpg")
        ]
    },
    q2: {
        name: "q2",
        question: "第一次約會\n會想要去哪種類型的餐廳？",
        A: "/測驗題目/q6",
        B: "/測驗題目/q7",
        progress: 20,
        preloadPics: [
            require("./images/topic/q6/bg.jpg"),
            require("./images/topic/q7/bg.jpg")
        ]
    },
    q3: {
        name: "q3",
        question: "第一次約會\n通常是誰付錢？",
        A: "/測驗題目/q5",
        B: "/測驗題目/q4",
        progress: 20,
        preloadPics: [
            require("./images/topic/q5/bg.jpg"),
            require("./images/topic/q4/bg.jpg")
        ]
    },
    q4: {
        name: "q4",
        question: "用餐過程中\n聊什麼話題你會更放鬆？",
        A: "/測驗題目/q7",
        B: "/測驗題目/q5",
        progress: 50,
        preloadPics: [
            require("./images/topic/q7/bg.jpg"),
            require("./images/topic/q5/bg.jpg")
        ]
    },
    q5: {
        name: "q5",
        question: "聊天過程\n發現有些看法不一樣，你會？",
        A: "/輸入姓名/紅薏仁",
        B: "/測驗題目/q6",
        progress: 75,
        preloadPics: [
            require("./images/topic/q6/bg.jpg"),
            require("./images/name/bg.jpg")
        ]
    },
    q6: {
        name: "q6",
        question: "用餐完的\n下個行程想去？",
        A: "/輸入姓名/鳳梨",
        B: "/輸入姓名/米粹",
        progress: 90,
        preloadPics: [
            require("./images/name/bg.jpg")
        ]
    },
    q7: {
        name: "q7",
        question: "走路時\n主動把你拉到內側",
        A: "/輸入姓名/東方美人茶",
        B: "/輸入姓名/桂竹",
        progress: 90,
        preloadPics: [
            require("./images/name/bg.jpg")
        ]
    },
};

export default quizData;
