import React, {useEffect} from 'react';
import button from "../images/coupon/btn_1.png";
import bg from "../images/coupon/bg.jpg";
import gsap from "gsap";

const Coupon = () => {
    useEffect(() => {
        gsap.fromTo("#root", { opacity: 0}, { opacity: 1, x: 0 });
    }, [])
    return (
        <div className={"page-bg flex flex-column"} style={{backgroundImage: "url("+bg+")"}}>
            <div style={{flex: 1.9}}></div>
            <div style={{flex: 2.3}} className={"flex flex-column"}>
                <div style={{flex: 7}}></div>
                <div style={{flex: 1}} className={"flex align-items-center justify-content-center"}>
                    <a href={bg} download={"coupon"}>
                        <img className={"button"} alt={"Save screen"} src={button}/>
                    </a>
                </div>
                <div style={{flex: 1}}></div>
            </div>
            <div style={{flex: 0.5}}></div>
        </div>
    )
}
export default Coupon;

